<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold">
          {{ is_re_enroll ? "Rematrícula" : "Matrícula" }}
        </h2>
      </b-col>
    </b-row>

    <div class="sticky-sidebar" ref="stickySidebar">
      <b-col class="sidebar" ref="sidebar">
        <ul>
          <li v-for="(item, index) in stepMenu" :key="`menu-${index + 1}`">
            <button
              :class="
                currentStep == item.id
                  ? 'current'
                  : '' || currentStep > item.id || item.percentage <= percentage
                  ? 'done'
                  : ''
              "
              @click="currentItemAside(item.step_name, item.id)"
              :style="
                item.percentage > percentage || percentage > 70
                  ? 'pointer-events: none; cursor: default;'
                  : ''
              "
            >
              <span>{{ index + 1 }}</span
              ><b>{{ item.name }}</b>
            </button>
          </li>
        </ul>
      </b-col>

      <!-- Form -->
      <b-col class="form-content">
        <form
          class="information content accordion"
          onkeydown="return event.key != 'Enter';"
        >
          <b-row>
            <b-col class="text-required">
              <p class="orange">* Dados obrigatórios</p>
              <p class="text-blue">
                ** Dados solicitados nos termos da Resoluçao CME/BH N01/2019
              </p>
            </b-col>
          </b-row>
          <div class="loadingView" v-if="loading || changing">
            <LoadingList v-model="loading" text="Carregando dados." />

            <LoadingList v-model="changing" text="Carregando dados." />
          </div>
          <div class="loadingView" v-if="downloadingContract && !loading">
            {{ window.scrollTo({ top: 0, behavior: "smooth" }) }}
            <LoadingList
              v-model="downloadingContract"
              text="Carregando dados."
            />
          </div>
          <div v-if="!changing">
            <!-- Step 1 -->
            <div
              :class="currentStep == 1 ? 'steps current' : 'steps accordion'"
            >
              <StudentData v-model="contentForm.StudentData" v-if="!loading" />
            </div>

            <div :class="currentStep == 2 ? 'steps current' : 'steps'">
              <Contractor v-model="contentForm.Contractor" v-if="!loading" />
            </div>

            <div :class="currentStep == 3 ? 'steps current' : 'steps'">
              <Filiation v-model="contentForm.Filiation" v-if="!loading" />
            </div>

            <div :class="currentStep == 4 ? 'steps current' : 'steps'">
              <Complementary
                v-model="contentForm.Complementary"
                v-if="!loading"
              />
            </div>

            <div :class="currentStep == 5 ? 'steps current' : 'steps'">
              <Authorizations
                v-model="contentForm.Authorizations"
                v-if="!loading"
              />
            </div>

            <div :class="currentStep == 6 ? 'steps current' : 'steps'">
              <Print
                v-model="contentForm.Print"
                v-if="!loading"
                :formEnroll="contentForm"
              />
            </div>

            <div :class="currentStep == 7 ? 'steps current' : 'steps'">
              <Submit
                v-model="contentForm.Submit"
                v-if="!loading"
                :enrollmentId="this.$route.params.id"
              />
            </div>

            <div class="btn-wrap">
              <b-button
                class="outline"
                :disabled="currentStep == 1 || currentStep == 7 ? true : false"
                @click="prevPage(currentStep)"
                v-show="currentStep != 1"
                type="button"
                >Voltar</b-button
              >
              <b-button
                :class="`${currentStep == maxPage ? 'send-form' : ''}`"
                @click="nextPageConfirm(currentStep)"
                :disabled="!isValid || loading"
                type="button"
              >
                {{
                  currentStep >= maxPage
                    ? "Finalizar"
                    : stepMenu[currentStep - 1].component !== "Print"
                    ? "Salvar e continuar"
                    : (rdaUnit === "POUSO_ALEGRE" &&
                        (currentYear === 2022 || currentYear === 2023)) ||
                      (rdaUnit !== "POUSO_ALEGRE" && currentCycle === 8)
                    ? "Salvar e continuar"
                    : "Gerar Contrato"
                }}
              </b-button>
            </div>
          </div>
        </form>
      </b-col>
      <!-- End Form -->
    </div>
  </b-container>
</template>

<script>
import StudentData from "./form/StudentData.vue";
import Contractor from "./form/Contractor.vue";
import Filiation from "./form/Filiation.vue";
import Complementary from "./form/Complementary.vue";
import Authorizations from "./form/Authorizations.vue";
import Print from "./form/Print.vue";
import Submit from "./form/Submit.vue";
import LoadingList from "@/components/LoadingList.vue";
import DataService from "@/services/enrollments.service.js";
import moment from "moment";
import httpRda from "../../http-rda";
import { mapGetters } from "vuex";

export default {
  name: "dados-do-aluno",
  components: {
    StudentData,
    Contractor,
    Filiation,
    Complementary,
    Authorizations,
    Print,
    Submit,
    LoadingList,
  },
  data() {
    return {
      is_re_enroll: false,
      arrCycles: [],
      changing: false,
      percentage: 0,
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      currentYear: null,
      currentCycle: null,
      cycles: [],
      currentStep: 1,
      currentComponent: "StudentData",
      downloadingContract: false,
      maxPage: 7,
      minPage: 1,
      loading: false,
      stepMenu: [
        {
          id: 1,
          name: "Dados do Aluno",
          step_name: "dados-do-aluno",
          component: "StudentData",
          percentage: 0,
        },
        {
          id: 2,
          name: "Contratantes",
          step_name: "contratantes",
          component: "Contractor",
          percentage: 10,
        },
        {
          id: 3,
          name: "Filiação",
          step_name: "filiacoes",
          component: "Filiation",
          percentage: 30,
        },
        {
          id: 4,
          name: "Complementares",
          step_name: "complementares",
          component: "Complementary",
          percentage: 40,
        },
        {
          id: 5,
          name: "Autorizações",
          step_name: "autorizacao",
          component: "Authorizations",
          percentage: 60,
        },
        {
          id: 6,
          name: "Revisão",
          step_name: "revisao",
          component: "Print",
          percentage: 70,
        },
        {
          id: 7,
          name: "Documentos",
          step_name: "documento",
          component: "Submit",
          percentage: 90,
        },
      ],
      stepMenuOrder: [
        {
          order: 1,
          name: "EnrollmentStudent",
        },
        {
          order: 2,
          name: "EnrollmentResponsibles",
        },
        {
          order: 3,
          name: "EnrollmentResponsibles",
        },
        {
          order: 4,
          name: "EnrollmentComplement",
        },
        {
          order: 5,
          name: "EnrollmentAuthorization",
        },
        {
          order: 6,
          name: "printed_at",
        },
        {
          order: 7,
          name: "EnrollmentFile",
        },
      ],
      breadcrumb: [
        {
          text: "",
          href: null,
          active: true,
        },
      ],
      allContentForm: null,
      contentForm: {
        StudentData: {
          photo: null,
          name: "",
          nationality: "",
          city_origin: "",
          ethnicity: "",
          birth_date: "",
          country_origin: "",
          residence_zone: "",
          guardian: "",
          gender: "",
          state_origin: "",
          skin_color: "",
          lives_with: "",
          previous_school: "",
          phone1: "",
          phone2: "",
          email: "",
          birth_certificate: "",
          rg: "",
          cpf: "",
          grade: "",
          cycle_id: null,
          year_id: null,
          time_period: "",
          hasSiblings: null,
          EnrollmentStudentBrothers: [],
          Address: {
            id: null,
            cep: null,
            number: null,
            complement: null,
            street: null,
            neighborhood: null,
            city: null,
            state: null,
            country: null,
          },
        },
        Contractor: [
          {
            type: "A",
            relationship: "",
            is_guardian: "",
            is_pedagogical_contact: "",
            is_financial_contact: "",
            is_address_same_student: false,
            photo: null,
            name: this.userName,
            occupation: "",
            workplace: "",
            nationality: "",
            country_origin: "",
            talents: "",
            phone1: "",
            phone2: "",
            phone_commercial: "",
            email: "",
            allow_email: false,
            cpf: "",
            rg: "",
            Address: {
              id: null,
              cep: null,
              number: null,
              complement: null,
              street: null,
              neighborhood: null,
              city: null,
              state: null,
              country: null,
            },
            userCPF: "",
            userName: "",
            userPhone1: "",
            userPhone2: "",
          },
          {
            type: "B",
            relationship: "",
            is_guardian: "",
            is_pedagogical_contact: "",
            is_financial_contact: "",
            is_address_same_student: false,
            photo: null,
            name: this.userName,
            occupation: "",
            workplace: "",
            nationality: "",
            country_origin: "",
            talents: "",
            phone1: "",
            phone2: "",
            phone_commercial: "",
            email: "",
            allow_email: false,
            cpf: "",
            rg: "",
            Address: {
              id: null,
              cep: null,
              number: null,
              complement: null,
              street: null,
              neighborhood: null,
              city: null,
              state: null,
              country: null,
            },
            userCPF: "",
            userName: "",
            userPhone1: "",
            userPhone2: "",
          },
        ],
        Filiation: [
          // {
          //   relationship: "",
          //   is_guardian: "",
          //   is_pedagogical_contact: "",
          //   is_financial_contact: "",
          //   photo: null,
          //   name: "",
          //   occupation: "",
          //   workplace: "",
          //   nationality: "",
          //   country_origin: "",
          //   talents: "",
          //   phone1: "",
          //   phone2: "",
          //   phone_commercial: "",
          //   email: "",
          //   allow_email: false,
          //   cpf: "",
          //   rg: "",
          //   cep: "",
          //   state: "",
          //   city: "",
          //   neighborhood: "",
          //   street: "",
          //   complement: "",
          //   number: "",
          //   country: "",
          // },
        ],
        Complementary: {
          vaccines_up_to_date: false,
          health_problem: false,
          health_problem_description: "",
          special_accompaniment: false,
          special_accompaniment_description: "",
          special_care: false,
          special_care_description: "",
          medication: false,
          medication_description: "",

          food_restriction: false,
          food_restriction_description: "",
          limitation: false,
          limitation_description: "",
          individual_accompaniment: false,
          individual_accompaniment_description: "",
          healthcare: false,
          healthcare_description: "",

          contact1: "",
          contact1_phone: "",
          contact2: "",
          contact2_phone: "",

          return_to_home: "Acompanhado por",

          EnrollmentComplementContacts: [
            {
              photo: null,
              name: "",
              relationship: "",
              number1: "",
              number2: "",
            },
          ],
        },
        Authorizations: {
          excursion: "",
          leave_school_break: "",
          leave_school_antecipated: "",
          shower: "",
        },
        Print: {},
        Submit: [
          {
            title: "Carteira de identidade do aluno (Frente e Verso)",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "identity",
                url: "",
              },
              {
                file: null,
                progress: null,
                status: false,
                fileName: "identity_back",
                url: "",
              },
            ],
          },
          {
            title: "CPF do aluno",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "cpf",
                url: "",
              },
            ],
          },
          {
            title: "Cartão de vacinas do aluno",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "vaccination_record",
                url: "",
              },
            ],
          },
          {
            title: "Declaração de transferência",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "transference_declaration",
                url: "",
              },
            ],
          },
          {
            title: "Declaração de quitação emitida pela escola anterior",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "discharge",
                url: "",
              },
            ],
          },
          {
            title: "Histórico escolar",
            isRequired: false,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "school_record",
                url: "",
              },
            ],
          },
          {
            title: "Carteira de identidade do contratante A (Frente e Verso)",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "identity_contractor_a",
                url: "",
              },
              {
                file: null,
                progress: null,
                status: false,
                fileName: "identity_contractor_a_back",
                url: "",
              },
            ],
          },
          {
            title: "Carteira de identidade do contratante B (Frente e Verso)",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "identity_contractor_b",
                url: "",
              },
              {
                file: null,
                progress: null,
                status: false,
                fileName: "identity_contractor_b_back",
                url: "",
              },
            ],
          },
          {
            title: "CPF dos contratantes",
            isRequired: true,

            files: [
              {
                file: null,
                progress: null,
                status: false,
                fileName: "cpf_contractor_a",
                url: "",
              },
              {
                file: null,
                progress: null,
                status: false,
                fileName: "cpf_contractor_b",
                url: "",
              },
            ],
          },
        ],
      },
    };
  },

  computed: {
    ...mapGetters({
      complement: "enrollment/getComplement",
    }),

    enrollment_id() {
      return this.$route.params.id;
    },

    watch: {
      contentForm: {
        handler() {
          this.isValid();
        },
        deep: true,
      },
    },
    isValid() {
      function checkAddressFormFields(item) {
        if (
          item.is_address_same_student === "Não" ||
          item.is_address_same_student === false
        ) {
          return (
            item.Address.cep &&
            item.Address.cep !== null &&
            item.Address.number &&
            item.Address.number !== null &&
            item.Address.street &&
            item.Address.street !== null &&
            item.Address.neighborhood &&
            item.Address.neighborhood !== null &&
            item.Address.city &&
            item.Address.city !== null &&
            item.Address.state &&
            item.Address.state !== null
          );
        } else if (
          item.is_address_same_student === "Sim" ||
          item.is_address_same_student === true
        ) {
          return true;
        }
      }

      const formStudentData = this.contentForm.StudentData;
      const formContractor = this.contentForm.Contractor;
      const formFiliation = this.contentForm.Filiation;
      const formComplementary = this.contentForm.Complementary;
      const formAuthorizations = this.contentForm.Authorizations;
      const formSubmit = this.contentForm.Submit;

      // Form datas
      //StudentData;

      const dataValidateStudentData =
        !formStudentData.photo ||
        !(formStudentData.name && this.isCompoundName(formStudentData.name)) ||
        !formStudentData.birth_date ||
        !formStudentData.gender ||
        !formStudentData.nationality ||
        !formStudentData.country_origin ||
        !formStudentData.skin_color ||
        !formStudentData.city_origin ||
        !formStudentData.residence_zone ||
        !formStudentData.lives_with ||
        !formStudentData.ethnicity ||
        !formStudentData.guardian ||
        !formStudentData.grade ||
        !formStudentData.Address.cep ||
        !formStudentData.Address.state ||
        !formStudentData.Address.city ||
        !formStudentData.Address.neighborhood ||
        !formStudentData.Address.street ||
        !formStudentData.Address.number ||
        !formStudentData.phone1 ||
        !formStudentData.email ||
        !(formStudentData.rg || formStudentData.birth_certificate) ||
        !formStudentData.cpf;

      // Contractor
      const dataValidateContractor = formContractor.map((contractor) => {
        const allData =
          !contractor.relationship ||
          !contractor.is_guardian ||
          !contractor.is_pedagogical_contact ||
          !contractor.is_financial_contact ||
          !contractor.photo ||
          !(contractor.name && this.isCompoundName(contractor.name)) ||
          !contractor.birth_date ||
          !contractor.occupation ||
          !contractor.workplace ||
          !contractor.nationality ||
          !contractor.country_origin ||
          !contractor.phone1 ||
          !contractor.email ||
          !contractor.cpf ||
          !contractor.rg ||
          !checkAddressFormFields(contractor);

        return allData;
      });

      // Complemantary
      let validateContacts =
        formComplementary.return_to_home === "Acompanhado por"
          ? formComplementary.EnrollmentComplementContacts?.map((item) => {
              const validate =
                !item.name ||
                !item.number1 ||
                !item.relationship ||
                !item.photo;
              return validate ? validate : null;
            })
          : [null];
      validateContacts = validateContacts?.every((e) => e === null);

      //Submit

      let dataValidateSubmit = formSubmit;

      dataValidateSubmit = dataValidateSubmit.map((e) => {
        return e?.files.every((item) => item.status === true);
      });
      dataValidateSubmit = dataValidateSubmit.every((cur) => cur === true);

      if (
        !dataValidateSubmit &&
        this.rdaUnit === "POUSO_ALEGRE" &&
        (this.currentYear === 2022 || this.currentYear === 2023)
      ) {
        dataValidateSubmit = true;
      }

      const dataValidateComplementaryAux =
        !formComplementary.contact1 ||
        !formComplementary.contact1_phone ||
        !formComplementary.contact2 ||
        !formComplementary.contact2_phone ||
        formComplementary.food_restriction
          ? !formComplementary.food_restriction_description
          : null || formComplementary.health_problem
          ? !formComplementary.health_problem_description
          : null || formComplementary.healthcare
          ? !formComplementary.healthcare_description
          : null || formComplementary.individual_accompaniment
          ? !formComplementary.individual_accompaniment_description
          : null || formComplementary.limitation
          ? !formComplementary.limitation_description
          : null || formComplementary.medication
          ? !formComplementary.medication_description
          : null || formComplementary.special_accompaniment
          ? !formComplementary.special_accompaniment_description
          : null || formComplementary.special_care
          ? !formComplementary.special_care_description
          : null;

      const dataValidateComplementary = validateContacts
        ? null
        : true || !dataValidateComplementaryAux;

      // Authorizations
      let dataValidateAuthorizations = Object.values(formAuthorizations);
      dataValidateAuthorizations = dataValidateAuthorizations.every(
        (e) => e !== ""
      );

      //Filiation
      const dataValidateFiliation =
        formFiliation.length === 2
          ? formFiliation.map((item) => {
              if (item.previousInformed == null) {
                return false;
              }

              switch (item.previousInformed) {
                case "Sim":
                case true:
                  return item.formData !== null &&
                    item.formData.id !== undefined
                    ? false
                    : true;
                case "Não":
                case false:
                  if (item.deceased && !item.not_applicable) {
                    return (
                      !item.formData.relationship ||
                      !(
                        item.formData.name &&
                        this.isCompoundName(item.formData.name)
                      )
                    );
                  } else if (!item.deceased && item.not_applicable) {
                    return !item.formData.name == "Não há";
                  } else {
                    return (
                      !item.formData.is_guardian ||
                      !item.formData.relationship ||
                      !item.formData.is_pedagogical_contact ||
                      !item.formData.photo ||
                      !(
                        item.formData.name &&
                        this.isCompoundName(item.formData.name)
                      ) ||
                      !item.formData.occupation ||
                      !item.formData.workplace ||
                      !item.formData.nationality ||
                      !item.formData.country_origin ||
                      !item.formData.talents ||
                      !item.formData.email ||
                      !item.formData.cpf ||
                      !item.formData.phone1 ||
                      !item.formData.rg ||
                      !checkAddressFormFields(item.formData)
                    );
                  }
                default:
                  break;
              }
            })
          : false;

      // condictions
      switch (this.currentComponent) {
        case "StudentData":
          return dataValidateStudentData ? false : true;
        case "Contractor":
          return dataValidateContractor.every((cur) => cur !== true);

        case "Complementary":
          return dataValidateComplementary ? false : true;

        case "Authorizations":
          return dataValidateAuthorizations ? true : false;

        case "Print":
          return true;

        case "Submit":
          if (dataValidateSubmit) {
            httpRda.patch(`/enrollments/${this.enrollment_id}/setReenroll`, {
              enrollment_file: dataValidateSubmit,
            });
          }
          return dataValidateSubmit ? true : false;
        case "Filiation":
          return dataValidateFiliation === false
            ? false
            : dataValidateFiliation.every((cur) => cur !== true);
        default:
          return null;
      }
    },
  },

  methods: {
    fixBirthDate(originDate) {
      let auxDate = moment(originDate, "DD/MM/YYYY");

      if (!auxDate.isValid()) {
        return (originDate = moment(originDate, "DD/MM/YYYY")
          .toDate()
          .toISOString());
      } else {
        return auxDate.toISOString();
      }
    },

    isCompoundName(name) {
      name = name.trim();
      const nameArr = name.split(" ");

      if (nameArr.length >= 2) {
        return true;
      } else {
        return false;
      }
    },

    async getCycles() {
      this.cycles = await httpRda
        .get("/cycles")
        .then((resp) => resp.data.cycles);
    },

    async getGrades() {
      await httpRda.get(`cycles-to-enrollment`).then((resp) => {
        this.arrCycles = resp.data.cycles;
      });
    },

    setStudentGrade(grade, turn) {
      if ((grade, turn)) {
        for (const lvl in this.arrCycles[turn]) {
          const classrooms = this.arrCycles[turn][lvl];

          const foundData = classrooms.find(
            (classroom) => classroom.grade === grade
          );

          if (foundData) {
            return foundData;
          }
        }

        return null;
      }
    },

    // Step
    currentItemAside(step, id) {
      this.getEnrollmentPercentage();
      this.loading = true;
      this.$router
        .replace({
          params: { step: step },
        })
        .catch(() => {});
      this.currentStep = id;

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });

      setInterval(() => {
        this.loading = false;
      }, 1000);

      // Form
      this.createUpdateEnrollment();
    },

    async nextPageConfirm(current) {
      this.changing = true;
      if (this.is_re_enroll && current === 1) {
        this.$swal
          .fire({
            title: "Confirmação de dados",
            text: "Todos os dados foram conferidos e atualizados? Assegure-se de ter utilizado uma foto atualizada.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#68c5b7",
            cancelButtonColor: "#d33",
            confirmButtonText: "Confirmar",
            cancelButtonText: "Cancelar",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.nextPage(current);
            }
          })
          .catch((error) => {
            this.$swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data.message,
              footer: "Por favor, tente novamente mais tarde.",
            });

            return error.response;
          });
      } else {
        await this.nextPage(current);
      }
    },

    async nextPage(current) {
      let submitType = "";

      let currentId = parseInt(current);
      let currentPageItem = null;
      try {
        this.loading = true;
        submitType = "update";

        this.getEnrollmentPercentage();
        switch (this.stepMenu[currentId - 1].component) {
          case "StudentData":
            await this.studentDataForm(submitType);
            break;

          case "Contractor":
            await this.contractorDataForm(submitType);
            break;
          case "Filiation":
            await this.filiationDataForm(submitType);
            break;

          case "Complementary":
            await this.createUpdateComplement(submitType);
            break;

          case "Authorizations":
            await this.createUpdateAuthorizations(submitType);
            break;

          case "Print":
            await this.getPrintData(submitType);
            break;

          case "Submit":
            this.$router.push({ path: "/matriculas" });
            break;
          default:
            break;
        }

        if (currentId < this.maxPage) {
          currentId += 1;
          this.currentStep = currentId;
        }

        this.loading = false;
        currentPageItem = this.stepMenu[this.currentStep - 1].step_name;
        this.currentItemAside(currentPageItem, currentId);
        this.changing = false;
      } catch (error) {
        this.loading = false;
        this.downloadingContract = false;
        if (!error.message.includes("step_name")) {
          this.$swal.fire({
            icon: "error",
            title: "Ocorreu um erro!",
            text: error.message,
            footer: `Ocorreu Um Erro! - ${
              this.stepMenu[currentId - 1].component
            } - ${submitType}`,
          });
        }
      }
    },

    prevPage(current) {
      this.getEnrollmentPercentage();
      let currentId = parseInt(current);
      let currentPageItem = null;

      this.loading = true;

      if (currentId > this.minPage) {
        currentId -= 1;
        this.currentStep = currentId;
      }

      this.loading = false;
      currentPageItem = this.stepMenu[this.currentStep - 1].step_name;
      this.currentItemAside(currentPageItem, currentId);

      // Form
      this.createUpdateEnrollment();
    },

    // Form Data Submits
    async studentDataForm(type) {
      const { ...student } = this.contentForm.StudentData;
      let studentPhoto = null;
      const enrollment_id = this.$route.params.id;
      const gradeData = this.setStudentGrade(
        student.grade,
        student.time_period.toLowerCase()
      );

      switch (type) {
        case "update":
        case "post":
          student.is_re_enroll = this.is_re_enroll;
          studentPhoto = student.photo;
          student.photo = undefined;
          student.cycle_id = gradeData.cycle_id;
          student.year_id = gradeData.year_id;

          student.birth_date = this.fixBirthDate(
            this.contentForm.StudentData.birth_date
          );
          student.cpf = student.cpf?.replace(/[^\w\s]/gi, "");
          student.rg = student.rg?.replace(/[^\w\s]/gi, "");

          // Post API
          this.downloadingContract = true;
          await httpRda
            .patch(`/enrollments/${enrollment_id}/students`, student)
            .then(async () => {
              if (typeof studentPhoto !== "string") {
                await httpRda
                  .patch(
                    `/enrollments/${enrollment_id}/students`,
                    studentPhoto,
                    {
                      headers: { "Content-Type": "multipart/form-data" },
                    }
                  )
                  .catch((error) => {
                    throw new Error(error.response.data.details);
                  });
              }

              await httpRda.patch(`/enrollments/${enrollment_id}/setReenroll`, {
                enrollment_student: this.is_re_enroll,
              });
            })
            .catch((error) => {
              this.downloadingContract = false;
              throw new Error(error.response.data.details);
            });

          this.downloadingContract = false;
          break;
        default:
          await DataService.getEnrollmentStudent(
            enrollment_id,
            this.contentForm.StudentData
          )
            .then((response) => {
              this.contentForm.StudentData = response.data.enrollmentStudent;
              this.contentForm.StudentData.birth_date = moment(
                this.contentForm.StudentData.birth_date
              ).format("DD/MM/YYYY");
            })
            .catch((error) => {
              throw new Error(error.response.data.message);
            });
          break;
      }
    },

    async contractorDataForm(type) {
      const enrollment_id = this.$route.params.id;
      const contractor = this.contentForm.Contractor;

      let arrContractors = [];

      switch (type) {
        case "update":
        case "post":
          for (const contractor of contractor) {
            if (typeof contractor.photo !== "string") {
              arrContractors.push({
                photo: contractor.photo,
                id: null,
                email: contractor.email,
              });
              contractor.photo = undefined;
            }
            contractor.is_re_enroll = this.is_re_enroll;

            contractor.birth_date = this.fixBirthDate(contractor.birth_date);
          }

          await httpRda
            .patch(`/enrollments/${enrollment_id}/contractor`, {
              contractor: contractor,
            })
            .then(async (resp) => {
              for (const responsible of resp.data.contractors) {
                const index = arrContractors.findIndex(
                  (contractor) => contractor.email == responsible.email
                );
                if (index != -1) {
                  arrContractors[index].id = responsible.id;
                }
              }
              await httpRda.patch(
                `/enrollments/${this.enrollment_id}/setReenroll`,
                { enrollment_responsibles_contractors: this.is_re_enroll }
              );
            })
            .catch((error) => {
              throw new Error(error.response.data.msg);
            });

          if (arrContractors.length > 0) {
            arrContractors.map(async (contractor) => {
              await httpRda
                .patch(
                  `/enrollments/${enrollment_id}/responsible/${contractor.id}`,
                  contractor.photo,
                  {
                    headers: { "Content-Type": "multipart/form-data" },
                  }
                )
                .catch((error) => {
                  throw new Error(error.response.data.msg);
                });
            });
          }
          break;
        default:
          await httpRda
            .get(`/enrollments/${enrollment_id}/responsibles`)
            .then((response) => {
              this.contentForm.Contractor = response.data.responsibles
                .filter((responsible) =>
                  responsible.ResponsibleTypes[0].type.includes("contractor")
                )
                .map((responsible) => {
                  responsible.birth_date = moment(
                    responsible.birth_date
                  ).format("DD/MM/YYYY");
                  return responsible;
                });
            })
            .catch((error) => {
              throw new Error(error.response.data.msg);
            });
          break;
      }
    },

    async filiationDataForm(type) {
      const enrollment_id = this.$route.params.id;
      let filiate = this.contentForm.Filiation;
      let previousInformedAux;
      let arrFiliates = [];

      filiate = filiate.map((filiate) => {
        if (filiate.type != undefined) {
          return {
            ...filiate,
            type: `filiation ${filiate.type
              .substring(filiate.type.length - 1)
              .toLocaleLowerCase()}`.toLocaleLowerCase(),
            is_re_enroll: this.is_re_enroll,
          };
        }
      });

      switch (type) {
        case "update":
        case "post":
          for (const filiate of filiate) {
            if (typeof filiate.formData.photo !== "string") {
              arrFiliates.push({
                photo: filiate.formData.photo,
                id: null,
                email: filiate.formData.email,
              });
              filiate.formData.photo = undefined;
            }
          }
          await httpRda
            .patch(`/enrollments/${enrollment_id}/filiation`, {
              filiate: filiate,
            })
            .then(async (response) => {
              for (const formData of response.data.result) {
                const index = arrFiliates.findIndex(
                  (filiateArr) => filiateArr.email == formData.filiate.email
                );
                if (index != -1) {
                  arrFiliates[index].id = formData.filiate.id;
                }
              }

              await httpRda.patch(
                `/enrollments/${this.enrollment_id}/setReenroll`,
                { enrollment_responsibles_filiations: this.is_re_enroll }
              );
            })
            .catch((error) => {
              throw new Error(error.response.data.details);
            });

          if (arrFiliates.length > 0) {
            arrFiliates.map(async (filiate) => {
              if (filiate && filiate.id != null) {
                await httpRda
                  .patch(
                    `/enrollments/${enrollment_id}/responsible/${filiate.id}`,
                    filiate.photo,
                    {
                      headers: { "Content-Type": "multipart/form-data" },
                    }
                  )
                  .catch((error) => {
                    throw new Error(error.response.data.details);
                  });
              }
            });
          }
          break;
        default:
          await httpRda
            .get(`/enrollments/${enrollment_id}/responsibles`)
            .then((response) => {
              this.contentForm.Filiation = [];
              for (const responsible of response.data.responsibles) {
                responsible.ResponsibleTypes.length > 1
                  ? (previousInformedAux = "Sim")
                  : (previousInformedAux = "Não");
                for (const types of responsible.ResponsibleTypes) {
                  if (types.type.includes("filiation")) {
                    this.contentForm.Filiation.push({
                      previousInformed: previousInformedAux,
                      formData: responsible,
                      not_applicable: null,
                      deceased: null,
                    });
                  }
                }
              }
            })
            .catch((error) => {
              throw new Error(error.response.data.msg);
            });

          break;
      }
    },

    async createUpdateAuthorizations(type) {
      const enrollment_id = this.$route.params.id;
      const { Authorizations } = this.contentForm;

      Authorizations.is_re_enroll = this.is_re_enroll;

      switch (type) {
        case "update":
        case "post":
          await httpRda
            .post(`/enrollments/${enrollment_id}/authorization`, Authorizations)
            .then(async () => {
              await httpRda.patch(
                `/enrollments/${this.enrollment_id}/setReenroll`,
                { enrollment_authorization: this.is_re_enroll }
              );
            })
            .catch((error) => {
              throw new Error(error.response.data.details);
            });
          break;
        default:
          await httpRda
            .get(`/enrollments/${enrollment_id}/authorization`)
            .then((response) => {
              this.contentForm.Authorizations =
                response.data.authorization !== null
                  ? response.data.authorization
                  : this.contentForm.Authorizations;
            });

          break;
      }
    },

    async createUpdateComplement(type) {
      const enrollment_id = this.$route.params.id;
      const complemantary = this.contentForm.Complementary;

      complemantary.is_re_enroll = this.is_re_enroll;

      switch (type) {
        case "update":
        case "post":
          await httpRda
            .patch(`/enrollments/${enrollment_id}/complement`, {
              complement: complemantary,
              id: enrollment_id,
            })
            .then(async () => {
              await httpRda.patch(
                `/enrollments/${this.enrollment_id}/setReenroll`,
                { enrollment_complement: this.is_re_enroll }
              );
            })
            .catch((error) => {
              throw new Error(error.response.data.msg);
            });
          await this.createUpdateContacts(enrollment_id);
          break;

        default:
          await httpRda
            .get(`/enrollments/${enrollment_id}/complement`)
            .then((response) => {
              this.contentForm.Complementary =
                response.data.enrollment_complement;
            });

          break;
      }
    },

    async getPrintData(type) {
      const enrollment_id = this.$route.params.id;

      switch (type) {
        case "update":
        case "post":
          this.loading = true;
          this.downloadingContract = true;
          await httpRda
            .post(`/enrollments/${enrollment_id}/finish`)
            .then(async () => {
              await httpRda
                .patch(`/enrollments/${enrollment_id}`, {
                  enrollment_id: enrollment_id,
                  is_printed: true,
                })
                .then(async () => {
                  let downloadContract = true;

                  if (
                    (this.rdaUnit === "POUSO_ALEGRE" &&
                      (this.currentYear === 2022 ||
                        this.currentYear === 2023)) ||
                    (this.rdaUnit !== "POUSO_ALEGRE" && this.currentCycle === 8)
                  ) {
                    downloadContract = false;
                  }

                  await httpRda.patch(
                    `/enrollments/${this.enrollment_id}/setReenroll`,
                    { printed_at: this.is_re_enroll }
                  );

                  if (downloadContract) {
                    await httpRda.get(
                      `/enrollments/${enrollment_id}/contract/download`
                    );
                  }
                  this.downloadingContract = false;
                });
            });
          break;

        default:
          this.loading = true;
          await httpRda
            .get(`/enrollments/${enrollment_id}`)
            .then((response) => {
              let contractors = [];
              let filiations = [];
              this.currentCycle =
                response.data &&
                response.data.enrollment &&
                response.data.enrollment.EnrollmentStudent &&
                response.data.enrollment.EnrollmentStudent.cycle_id;

              for (const responsible of response.data.enrollment
                .EnrollmentResponsibles) {
                for (const types of responsible.ResponsibleTypes) {
                  if (types.type.includes("contractor")) {
                    contractors.push({
                      ...responsible,
                      type: types.type
                        .replace("contractor", "Contratante")
                        .replace(
                          /\w\S*/g,
                          (m) =>
                            m.charAt(0).toUpperCase() +
                            m.substr(1).toLowerCase()
                        ),
                    });
                    contractors.sort((a, b) => {
                      if (a.type < b.type) {
                        return -1;
                      }
                      if (a.type > b.type) {
                        return 1;
                      }
                      return 0;
                    });
                  } else if (types.type.includes("filiation")) {
                    filiations.push(responsible);
                  }
                }
              }

              this.contentForm.Print = {
                ...response.data.enrollment,
                Contractor: contractors,
                Filiation: filiations,
              };
              this.loading = false;
            });
          this.loading = false;
          break;
      }
    },

    async createUpdateContacts(id) {
      if (this.contentForm.Complementary.return_to_home !== "Sozinho") {
        const toUpdate =
          this.contentForm.Complementary.EnrollmentComplementContacts.filter(
            (contact) => contact.id !== undefined
          );

        const toCreate =
          this.contentForm.Complementary.EnrollmentComplementContacts.filter(
            (contact) => contact.id === undefined
          );

        if (toUpdate.length) {
          toUpdate.map(async ({ photo, ...contact }) => {
            const bodyFormData = new FormData();
            bodyFormData.append("file", photo);

            await httpRda.patch(
              `/enrollments/${id}/contacts/${contact.id}`,
              contact
            );

            await httpRda.patch(
              `enrollments/${id}/contacts/${contact.id}`,
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
          });
        }

        if (toCreate.length) {
          let newId;
          toCreate.map(async ({ photo, ...contact }) => {
            const bodyFormData = new FormData();
            bodyFormData.append("file", photo);
            await httpRda
              .post(`/enrollments/${id}/contacts`, contact)
              .then((response) => {
                newId = response.data.complement.find(
                  (c) => c.name === contact.name
                ).id;
              });

            await httpRda.patch(
              `enrollments/${id}/contacts/${newId}`,
              bodyFormData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );
          });
        }
      }
    },

    createUpdateEnrollment() {
      this.currentComponent = this.stepMenu[this.currentStep - 1].component;

      switch (this.currentComponent) {
        case "StudentData":
          this.studentDataForm();
          break;

        case "Contractor":
          this.contractorDataForm();
          break;

        case "Filiation":
          this.filiationDataForm();
          break;

        case "Complementary":
          this.createUpdateComplement();
          break;

        case "Authorizations":
          this.createUpdateAuthorizations();
          break;

        case "Print":
          this.getPrintData();
          break;

        default:
          null;
      }
    },

    checkObject(key, obj) {
      switch (key) {
        case "EnrollmentFinance":
        case "signed_at":
          return true;
        case "EnrollmentStudent":
          obj[key] === null ? false : true;
          break;
        case "EnrollmentResponsibles":
          obj[key].some((e) => e.ResponsibleTypes);
          break;
        default:
          break;
      }
    },

    async getEnrollmentYear() {
      this.currentYear = await httpRda
        .get(`/enrollment/${this.$route.params.id}/year`)
        .then((response) => response.data.year);
    },

    async getEnrollmentPercentage() {
      this.percentage = await httpRda
        .get(`/enrollments/${this.$route.params.id}/percentage`)
        .then((response) => response.data.percentage);
    },
  },

  created() {
    this.getGrades();
    this.$route.name.includes("rematricula")
      ? (this.is_re_enroll = true)
      : (this.is_re_enroll = false);

    if (this.$router.currentRoute) {
      this.currentStep = this.$route.params.step;

      this.stepMenu.forEach((cur) => {
        if (cur.step_name == this.currentStep) {
          this.currentItemAside(this.currentStep, cur.id);
        }
      });

      // Set currentComponent
      this.currentComponent = this.stepMenu[this.currentStep - 1].component;
    }

    this.getCycles();
    this.getEnrollmentYear();
    this.getEnrollmentPercentage();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.container-rda {
  max-width: 1600px;
  margin: 0 auto;
}

.sticky-sidebar {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  position: relative;
  box-sizing: border-box;

  .sidebar {
    flex: 0 0 250px;
    padding: 0;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    padding-top: 50px;

    ul {
      margin: 0;
      padding: 0;

      li {
        width: 100%;
        list-style: none;

        button {
          background: transparent;
          width: 100%;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 15px;
          border: 0;
          opacity: 0.5;
          transition: 0.1s all ease-in-out;

          &.click {
            pointer-events: none;
            cursor: default;
          }

          &:hover {
            opacity: 1;
            transition: 0.2s all ease-in-out;
          }

          &.current {
            opacity: 1;
            transition: 0.2s all ease-in-out;
            pointer-events: all;
            cursor: pointer;

            span {
              color: $white;
              background: $purple;
              transition: 0.2s all ease-in-out;
            }
          }

          &.done {
            opacity: 1;
            transition: 0.2s all ease-in-out;
            pointer-events: all;
            cursor: pointer;

            span {
              color: $white;
              background: $green;
              transition: 0.2s all ease-in-out;
            }
          }

          span {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #b3b3b3;
            background: $gray;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50px;
          }

          b {
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #1a1a1a;
          }
        }
      }
    }
  }
  .content {
    box-sizing: border-box;
    padding-top: 50px;

    .steps {
      display: none;

      &.current {
        display: block;
      }
    }
  }
}

.btn-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;

  button {
    min-width: 150px;

    &.send-form {
      color: $white !important;
      background: $gradient1;
      border-color: transparent;

      &:hover,
      &:focus,
      &:active {
        border-color: transparent !important;
      }
    }
  }
}

.loadingView {
  width: 100%;
  height: 200px;
  position: relative;

  .loading {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;

    div {
      text-align: center;
      background: transparent;
      max-width: 158px !important;
      line-height: 15px;
      border: 0;
      display: flex !important;
      flex-direction: column;
      row-gap: 10px;
      box-shadow: none;
      transform: translateX(0px);
    }
  }
}

.form-content {
  [id*="accordion-"] {
    border-bottom: 1px solid #b3b3b3;
    padding-bottom: 30px;
  }
  .form-group {
    margin-bottom: 20px !important;

    label {
      font-size: 0.8rem;
    }
  }
  .photo-picture {
    border-radius: 6px;

    .photoImg {
      background: #cecece;
      width: 100%;
      padding: 5px;
      border-radius: 50%;
      object-fit: cover;
    }
    .btn {
      color: $green !important;
      background: transparent;
      border: 0;

      svg {
        fill: $green;
      }
    }
  }
  .card-header {
    background-color: transparent;
    border: none;
    padding: 0;
    position: relative;
    z-index: 0;

    h5 {
      border-bottom: 1px solid #b3b3b3;
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 30px 0;
      margin: 0;
      transition: 0.1s all ease-in-out;
      transition-delay: 0.3s;

      &:after {
        background: $gradient1;
        display: block;
        content: "";
        -webkit-mask: url("~@/assets/images/svg/arrow-down-enrollment.svg")
          no-repeat 50% 50%;
        mask: url("~@/assets/images/svg/arrow-down-enrollment.svg") no-repeat
          50% 50%;
        -webkit-mask-size: cover;
        mask-size: cover;
        width: 23px;
        height: 14px;
        transition: all 0.15s;
        transform: rotate(0deg);
      }

      &.not-collapsed {
        border-bottom: 0;
        transition: 0.1s all ease-in-out;

        &:after {
          transform: rotate(180deg);
        }
      }
    }
  }

  .collapse {
    position: relative;
    z-index: 100;
  }

  .multiselect__input,
  .multiselect__single {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .add-brother {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    svg {
      font-size: 16px !important;
      position: relative;
      top: -1px;
    }
  }

  .delete-col {
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-delete {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      top: 5px;
      cursor: pointer;

      svg {
        font-size: 25px;
        color: $red;
      }
    }
  }
  .status-box {
    justify-content: flex-start;
    margin: 32px 0 16px;
    .status-style {
      margin: 0;
      button {
        width: 210px;
      }
    }
    .info {
      align-items: center;
      font-size: 16px;
      margin: 0 20px;
    }
  }

  .row-enrollment {
    padding-top: 0;
  }
}
</style>
