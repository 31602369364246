<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="purple bold title">Notificações</h2>
      </b-col>
    </b-row>

    <b-row class="mt-50">
      <b-col>
        <b-form-group label="Usuário">
          <b-form-input
            type="search"
            size="sm"
            placeholder="Digite aqui"
            v-model="userName"
            @change="(event) => setUserName(event)"
          ></b-form-input>
        </b-form-group>
        <span
          class="clear-field"
          v-if="this.userName != null"
          @click="clearUsername"
          >Limpar filtro</span
        >
      </b-col>

      <b-col>
        <b-form-group>
          <label for="label">Tipo de mundança</label>
          <multiselect
            v-model="notificationsType"
            :options="notificationsTypes"
            :multiple="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Tipo"
            label="name"
            track-by="id"
            selectLabel=""
            selectedLabel=""
            deselectLabel=""
            @select="(event) => setNotificationType(event)"
          >
            <template slot="tag" slot-scope="{ option }">
              <span class="custom__tag">
                <span>{{ option.name }}</span>
              </span>
            </template>
          </multiselect>
        </b-form-group>
        <span
          class="clear-field"
          v-if="this.notificationsType != null"
          @click="clearNotificationsTypes"
          >Limpar filtro</span
        >
      </b-col>
      <b-col>
        <b-form-group class="calendar">
          <label>Data início</label>
          <Datepicker
            v-model="selectedDate.begin_date"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
            @selected="setBeginDate"
          ></Datepicker>
          <span
            class="clear-field"
            v-if="this.selectedDate.begin_date != null"
            @click="clearBeginDate"
            >Limpar filtro</span
          >
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group class="calendar">
          <label>Data final</label>
          <Datepicker
            v-model="selectedDate.end_date"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
            @selected="setEndDate"
          ></Datepicker>
          <span
            class="clear-field"
            v-if="this.selectedDate.end_date != null"
            @click="clearEndDate"
            >Limpar filtro</span
          >
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="notification in notifications"
            :key="notification.id"
          >
            <b-col
              :class="`name ${!notification.readed_at ? 'marked' : null} `"
            >
              <p class>
                <strong>{{ notification.EditorUser.name }}</strong> editou dados
                <strong>({{ notification.type }})</strong>
                <strong
                  v-if="
                    notification.EditorUser.id !== notification.EditedUser.id
                  "
                  ><span class="not-bold"> de </span
                  >{{ notification.EditedUser.name }}</strong
                >
                <strong v-else
                  ><span class="not-bold"> do </span> próprio usuário</strong
                >
              </p>
              <div v-for="detail in notification.details" :key="detail">
                <b-row>
                  <b-col class="title-col">
                    <strong>{{ detail.property }}</strong>
                  </b-col>
                  <b-col class="title-col">
                    {{ notification.created_at }}
                  </b-col>
                </b-row>
                <b-row class="changes">
                  <b-col>
                    <p class="title">Antes</p>
                    {{
                      detail.old ? detail.old : "Sem conteúdo anterior"
                    }}</b-col
                  >
                  <b-col cols="2"> ➡ </b-col>
                  <b-col>
                    <p class="title">Depois</p>
                    {{
                      detail.new ? detail.new : "Sem conteúdo anterior"
                    }}</b-col
                  >
                </b-row>
              </div>
            </b-col>
          </b-row>

          <b-row
            align-v="center"
            class="list-teacher"
            v-if="wasRequestMade && !notifications.length"
          >
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!wasRequestMade">
            <b-col>Carregando</b-col>
          </b-row>
          <b-row class="align-center" v-if="notifications && wasRequestMade">
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="count"
              aria-controls="teachersList"
              last-text
              first-text
            >
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import moment from "moment-timezone";
import httpRda from "../../http-rda";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";

library.add(faArrowAltCircleRight);

export default {
  name: "notificações",
  components: {
    LeftArrowIcon,
    Datepicker,
    // FontAwesomeIcon
  },

  data() {
    return {
      selectedDate: {},
      calendars: [],
      language: "ptBR",
      languages: lang,
      notifications: [],
      notificationsIds: [],
      notificationsTypes: [],
      notificationsType: null,
      userName: "",
      is_active: null,
      currentPage: 1,
      count: 0,
      perPage: 10,
      wasRequestMade: true,
      breadcrumb: [
        {
          text: "Coordenação",
          href: "/coordenacao",
        },
        {
          text: "Notificações",
          href: "/notificacoes",
          active: true,
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getAuthData",
    }),

    canEdit() {
      if (!this.user.role) return false;
      return !["teacher", "student", "parent"].includes(this.user.role[0]);
    },
  },

  methods: {
    formatNotifications(notifications) {
      notifications.map((notification) => {
        notification.created_at = moment
          .tz(notification.created_at, "America/Sao_Paulo")
          .format("[Data:] DD/MM/YYYY [às] HH:mm");

        this.notificationsIds.push(notification.id);
        return notification;
      });
    },

    clearNotificationsTypes() {
      this.notificationsType = null;
      this.currentPage = 1;
      this.searchNotifications();
    },

    clearUsername() {
      this.userName = null;
      this.currentPage = 1;
      this.searchNotifications();
    },

    clearBeginDate() {
      this.selectedDate.begin_date = null;
      this.currentPage = 1;
      this.searchNotifications();
    },

    clearEndDate() {
      this.selectedDate.end_date = null;
      this.currentPage = 1;
      this.searchNotifications();
    },

    setBeginDate(date) {
      this.selectedDate.begin_date = date;
      this.searchNotifications();
    },

    setEndDate(date) {
      this.selectedDate.end_date = date;
      this.searchNotifications();
    },

    setUserName(name) {
      this.userName = name;
      this.searchNotifications();
    },
    setNotificationType(typeObj) {
      this.notificationsType = typeObj.id;
      this.searchNotifications();
    },

    async searchNotifications() {
      const queryParams = {};

      this.wasRequestMade = false;

      if (
        this.userName !== undefined &&
        this.userName !== null &&
        this.userName !== "" &&
        this.userName !== " "
      ) {
        queryParams.name = this.userName;
      }
      if (
        this.notificationsType !== undefined &&
        this.notificationsType !== null
      ) {
        queryParams.type = this.notificationsType;
      }

      if (
        this.selectedDate.begin_date !== undefined &&
        this.selectedDate.begin_date !== null
      ) {
        queryParams.begin_date = this.selectedDate.begin_date;
      }

      if (
        this.selectedDate.end_date !== undefined &&
        this.selectedDate.end_date !== null
      ) {
        queryParams.end_date = this.selectedDate.end_date;
      }

      if (this.currentPage !== undefined && this.currentPage !== null) {
        queryParams.page = this.currentPage;
      }

      queryParams.pageSize = this.perPage;

      await httpRda
        .get("/notifications", { params: queryParams })
        .then((response) => {
          this.notifications = response.data.notifications;
          this.count = response.data.count;
        });

      this.formatNotifications(this.notifications);
      await this.updateReadedAt();

      this.wasRequestMade = true;
    },

    async getNotificationsTypes() {
      this.notificationsTypes = await httpRda
        .get("/notifications-types")
        .then((response) => response.data.types);
    },

    async updateReadedAt() {
      await httpRda.patch("/notifications", {
        ids: this.notificationsIds,
      });
    },
  },

  watch: {
    currentPage() {
      this.searchNotifications();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },

  async created() {
    this.getNotificationsTypes();
    this.searchNotifications();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";
.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .tags_count {
    background-color: #68c5b7;
    font-size: 18px;
    border-radius: 3px;
    color: #fff;
    margin-left: 10px;
    padding: 3px 10px;
    height: max-content;
  }
}
.title-col {
  margin-top: 15px;
}

.list-top {
  margin-top: 30px;
  margin-bottom: 30px;
}
.not-bold {
  font-weight: normal;
}
.list-teacher {
  background-color: $white;
  border: 1px solid $grey5;
  border-radius: 10px;
  margin-bottom: 15px;
  min-height: 80px;
  &:hover {
    border-color: transparent;
    background-image: linear-gradient($white, $white),
      radial-gradient(circle at top left, $orange, $purple);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }
  p {
    margin: 0;
  }

  .marked {
    border: 2px #b23066 solid;
    border-radius: 10px;
  }

  .disciplines {
    .name {
      display: inline-block;
      line-height: 22px;
      .comma {
        margin-right: 4px;
      }
      &:last-child {
        .comma {
          display: none;
        }
      }
    }
  }
  .cycles {
    display: flex;
    flex-wrap: wrap;
    .tags {
      background-color: $blue;
      border-radius: 5px;
      color: $white;
      font-weight: $bold;
      line-height: 28px;
      margin: 3px 12px 3px 0;
      max-width: 58px;
      padding: 0 3px;
      text-align: center;
      vertical-align: middle;
    }
  }
}
.outline {
  color: #68c5b7 !important;
  background: transparent;
  max-width: 220px !important;
  margin-right: 20px;

  &:hover {
    color: #fff !important;
  }
}

.changes {
  padding: 16px;
  margin: 20px 20px 0;
  border-top: 1px solid #ccc;
  box-sizing: border-box;

  .title {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: bold;
  }
}
</style>
