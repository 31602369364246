<template>
  <b-container class="container-rda" v-if="selected">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="profile-row">
          <b-col>
            <img
              v-if="selected.student.User.photo == null || ''"
              src="@/assets/images/default-photo.png"
              height="64"
              width="64"
              alt
            />
            <img
              v-else
              width="64"
              height="64"
              :src="`${selected.student.User.photo}`"
              alt
            />
            <div class="content-profile">
              <h5>{{ selected.student.User.name }}</h5>
              <p>
                Ciclo: {{ classroom.Cycle.name }} - {{ classroom.year_id }}° ano
              </p>
            </div>
          </b-col>
        </div>
      </b-col>
      <b-col>
        <b-row>
          <b-col>
            <div class="dash-box contents">
              <p>Conteúdos</p>
              <h1>{{ topics.count }}</h1>
            </div>
          </b-col>
          <b-col v-if="false">
            <div class="dash-box scripts">
              <p>Roteiros</p>
              <h1>{{ syllabuses.count }}</h1>
            </div>
          </b-col>
          <b-col v-if="selected && selected.student && selected.student.id">
            <router-link
              class="dash-box comments"
              :to="{
                name: 'faltas-e-presencas',
                params: { student_id: selected.student.id },
              }"
            >
              <p>Frequência</p>
              <h1>{{ attendances.frequency }}%</h1>
            </router-link>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="student-data mt-5 pt-5">
      <b-col>
        <h3>Dados</h3>
      </b-col>
      <div class="w-100 mb-4"></div>
      <b-col>
        <h5 class="grey4">Dados do aluno</h5>
      </b-col>
      <div class="w-100 mb-4"></div>
      <b-col>
        <StandardInput
          type="text"
          label="Nome"
          :placeholder="`${selected.student.User.name}`"
          disabled
        ></StandardInput>
      </b-col>
      <b-col>
        <StandardInput
          type="email"
          label="E-mail"
          :placeholder="`${selected.student.User.email}`"
          disabled
        ></StandardInput>
      </b-col>
      <b-col>
        <StandardInput
          type="text"
          label="Telefone"
          :placeholder="`${
            selected.student.User.Phones.length > 0
              ? selected.student.User.Phones.find((p) => p.id).number
              : 'Sem registro'
          }`"
          disabled
        ></StandardInput>
      </b-col>
      <b-col>
        <StandardInput
          type="text"
          label="Data de Nascimento"
          :placeholder="`${selected.student.User.birth_date}`"
          disabled
        ></StandardInput>
      </b-col>

      <div class="w-100"></div>
      <b-col>
        <StandardInput
          type="text"
          label="Tutor(a)"
          :placeholder="`${this.studentTutor ? this.studentTutor : ''}`"
          disabled
        ></StandardInput>
      </b-col>
      <b-col>
        <StandardInput
          type="text"
          label="Turma"
          :placeholder="`${
            classroom && classroom.designation ? classroom.designation : ''
          }`"
          disabled
        ></StandardInput>
      </b-col>
      <b-col md="4">
        <StandardInput
          type="text"
          label="Ciclo"
          :placeholder="
            classroom.Cycle.designation && classroom.Cycle.name
              ? `${classroom.Cycle.designation} (${classroom.Cycle.name}) - ${classroom.year_id}° ano`
              : null
          "
          disabled
        ></StandardInput>
      </b-col>

      <b-col>
        <StandardInput
          type="text"
          label="Data de Matrícula"
          :placeholder="`${studentEnrollmentCreatedAt}`"
          disabled
        ></StandardInput>
      </b-col>
      <div class="w-100"></div>
    </b-row>

    <b-row
      v-if="
        enrollmentData &&
        Array.isArray(enrollmentData) &&
        enrollmentData.length > 0
      "
    >
      <b-col md="4">
        <StandardInput
          type="email"
          label="Guarda"
          :placeholder="`${String(enrollmentData[0].guardian).toUpperCase()}`"
          disabled
        ></StandardInput>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h5 class="mb-4 grey4">Dados dos responsáveis</h5>
      </b-col>
    </b-row>
    <b-row class="student-data" v-if="ShowParentData">
      <b-col>
        <b-row v-for="parent in parents" v-bind:key="parent.id">
          <b-col>
            <StandardInput
              type="text"
              label="Nome"
              :placeholder="`${parent.Responsible.User.name}`"
              disabled
            ></StandardInput>
          </b-col>
          <b-col>
            <StandardInput
              type="text"
              label="E-mail"
              :placeholder="`${parent.Responsible.User.email}`"
              disabled
            ></StandardInput>
          </b-col>

          <b-col>
            <StandardInput
              type="text"
              label="Contato"
              :placeholder="`${
                parent.Responsible.User.Phones.length > 0
                  ? parent.Responsible.User.Phones[0].number
                  : 'Sem registro'
              }`"
              disabled
            ></StandardInput>
          </b-col>
          <b-col>
            <StandardInput
              type="text"
              label="Guarda"
              :placeholder="
                enrollmentData.find((e) => e.parent_id == parent.id).is_guardian
                  ? 'Sim'
                  : 'Não'
              "
              disabled
            ></StandardInput>
          </b-col>
          <b-col>
            <StandardInput
              type="text"
              label="Parentesco"
              :placeholder="`${
                parent.Responsible.relationship
                  ? parent.Responsible.relationship
                  : 'Sem registro'
              }`"
              disabled
            ></StandardInput>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        v-if="
          selected.student.parent1_id != null &&
          selected.student.parent2_id != null
        "
      >
        <b-button
          class="btn large blue"
          @click="ShowParentData = !ShowParentData"
          >{{ ShowParentData ? "Fechar" : "Ver mais" }}</b-button
        >
      </b-col>
      <b-col v-else>
        <p>Não há dados dos responsáveis cadastrados</p>
      </b-col>
    </b-row>
    <b-row class="pt-5 mt-5 mb-4">
      <b-col>
        <h3>Conteúdos em estudo</h3>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-if="topicsFormated && topicsFormated.length < 0">
        <p>Não há conteúdos em estudo</p>
      </b-col>
      <b-col v-else>
        <carousel
          :perPageCustom="[
            [360, 3],
            [1366, 6],
          ]"
          paginationColor="$grey5"
        >
          <slide v-for="subject in topicsFormated" v-bind:key="subject.id">
            <div
              @click="showModal(subject)"
              style="cursor: pointer"
              class="box-scripts"
            >
              <b-row>
                <b-col>
                  <SubjectsIcon :value="subject.designation" />
                  <h5>{{ subject.designation }}</h5>
                </b-col>
              </b-row>
              <b-row align-v="center" no-gutters>
                <b-col>
                  <span v-if="subject.topics && subject.topics.length > 1"
                    >Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span
                  >
                  <span v-if="subject.topics && subject.topics.length == 1"
                    >Há
                    <strong>{{ subject.topics.length }}</strong>
                    tópicos com status "EE"</span
                  >
                  <span v-if="subject.topics && subject.topics.length == 0"
                    >Não há tópicos</span
                  >
                </b-col>

                <div class="w-100"></div>
              </b-row>
            </div>
          </slide>
        </carousel>
      </b-col>
    </b-row>
    <SubjectsProgressBars
      :percentages="percentages"
      :subjects="subjects"
      :desiredPercentage="desiredPercentage"
    />
    <b-modal
      id="modal-script"
      hide-footer
      style="display: flex; justify-content: center; align-items: center"
      v-if="subjectSelected"
      :title="subjectSelected.designation"
    >
      <b-row
        class="rda-viewlist"
        v-for="(competence, index) in subjectSelected.competences"
        :key="`competence-${index}`"
      >
        <b-col cols="12">
          <h5 class="title">{{ competence.description }}</h5>
        </b-col>
        <b-col
          cols="12"
          v-for="(topic, indexS) in competence.topics"
          :key="`student-${indexS}`"
        >
          <div class="list-teacher item-rda-viewlist">
            <div class="cols text-col">
              <p>{{ topic }}</p>
            </div>

            <div class="cols">
              <p class="tags warning">EE</p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
  <b-container class="mt-5 container-rda" v-else-if="this.$route.params.id">
    <p>Carregando</p>
  </b-container>
  <b-container class="mt-5 container-rda" v-else>
    <p>Nenhum aluno selecionado</p>
  </b-container>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
import DataService from "@/services/students.service";
import SubjectsIcon from "@/components/svg/SubjectsIcon.vue";
import StandardInput from "@/components/inputs/StandardInput";
import httpRda from "../../../http-rda";
import moment from "moment";
import SubjectsProgressBars from "@/components/SubjectsProgressBars.vue";

export default {
  name: "Início",
  components: {
    Carousel,
    Slide,
    SubjectsIcon,
    StandardInput,
    SubjectsProgressBars,
  },
  data() {
    return {
      desiredPercentage: 10,
      topicsFormated: null,
      subjectScript: null,
      subjectSelected: null,
      percentagesDefault: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      percentages: [
        { number: 10, class: false },
        { number: 20, class: false },
        { number: 30, class: false },
        { number: 40, class: false },
        { number: 50, class: false },
        { number: 60, class: false },
        { number: 70, class: false },
        { number: 80, class: false },
        { number: 90, class: false },
        { number: 100, class: false },
      ],
      studentTutor: null,
      studentId: this.$route.params.id,
      studentEnrollmentCreatedAt: null,
      selected: null,
      ShowParentData: false,
      student: {
        User: {},
      },
      tutor: {
        User: {
          name: "",
        },
      },
      classroom: {
        Cycle: {
          name: "",
        },
      },
      attendances: [],
      syllabuses: [],
      topics: [],
      parents: [],
      enrollmentData: [],
      subjects: [],

      comments: "actives",

      breadcrumb: [
        {
          text: "Comunidade",
          href: "/comunidade",
        },
        {
          text: "Alunos",
          href: "/alunos",
        },
        {
          text: "Perfil",
          href: "/perfil",
          active: true,
        },
      ],
    };
  },

  computed: {
    tutors() {
      if (this.tutor) return this.tutor.User.name;
      else return "Sem registro";
    },
  },

  methods: {
    async getSubjectScript(student_id) {
      const subjects = await httpRda
        .get(`students/${student_id}/study_script`)
        .then((res) => res.data.subjectScript)
        .catch((err) => err);

      this.subjectScript = subjects;

      const topics = await subjects.reduce((acc, cur) => {
        const designation = cur.designation;
        const competences = cur.competences;

        let topics = cur.competences.reduce((acc, cur) => {
          const topics = cur.topics;
          acc.push(topics);
          return acc;
        }, []);

        topics = topics.flat();

        acc.push({ designation, competences, topics });

        return acc;
      }, []);

      this.topicsFormated = topics;
    },

    showModal(subject) {
      this.$bvModal.show("modal-script");
      this.subjectSelected = subject;
    },
    getSelected(id) {
      DataService.getStudent(id)
        .then((response) => {
          this.selected = response.data;
          this.selected.student.User.birth_date = moment(
            this.selected.student.User.birth_date
          ).format("DD/MM/YYYY");
        })
        .catch((error) => {
          return error;
        });

      this.getSubjectScript(id);

      DataService.getStudentAttendances(id)
        .then((response) => {
          this.attendances = response.data;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentClassroomCycle(id)
        .then((response) => {
          this.classroom = response.data.classroom;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentTutor(id)
        .then((response) => {
          this.tutor = response.data.tutor.Teacher.User;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentSyllabus(id)
        .then((response) => {
          this.syllabuses = response.data;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentSubjects(id)
        .then((response) => {
          this.subjects = response.data.subjects;
          if (response.data && response.data.desiredPercentage) {
            this.percentages = [];
            this.percentages = [...this.percentagesDefault];

            this.desiredPercentage = Number(response.data.desiredPercentage);

            this.percentages.sort((a, b) => a.number - b.number);
          }
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentTopics(id)
        .then((response) => {
          this.topics = response.data;
        })
        .catch((error) => {
          return error;
        });

      DataService.getStudentParents(id)
        .then((response) => {
          this.parents = response.data.parents;
          this.enrollmentData = response.data.enrollmentData;
        })
        .catch((error) => {
          return error;
        });
    },

    async getStudentTutor() {
      await httpRda
        .get(`/tutoring/student/${this.studentId}/tutor`)
        .then((response) => {
          this.studentTutor = response.data.tutor
            ? response.data.tutor.Teacher.User.name
            : null;
        });
    },

    async getStudentEnrollmentCreatedAt() {
      await httpRda
        .get(`/students/${this.studentId}/enrollment`)
        .then((response) => {
          this.studentEnrollmentCreatedAt = response.data.enrollment_created_at;
        });
    },
  },

  created() {
    this.getSelected(this.$route.params.id);
    this.getStudentTutor();
    this.getStudentEnrollmentCreatedAt();
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variables.scss";

.student-data {
  .form-group {
    margin-bottom: 40px;
  }
}

.rda-viewlist {
  margin-bottom: 30px;
  .title {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #36b67d;
    margin-bottom: 12px;
  }

  .item-rda-viewlist {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    .cols {
      .tags {
        min-width: 40px;
      }
    }

    .text-col {
      flex: 1;
    }
  }
}

.profile-row {
  background: $gradient3;
  border-radius: 10px;
  color: $white;
  padding: 28px 10px;
  position: relative;
  &:after {
    content: url("~@/assets/images/watermark-profile.png");
    right: 0;
    pointer-events: none;
    position: absolute;
    top: -5px;
  }
  .col {
    display: flex;
    align-items: center;
    img {
      margin-right: 40px;
    }
    .content-profile {
      h5 {
        margin-bottom: 14px;
      }
      p {
        margin: 0;
      }
    }
  }
}

.dash-box {
  border-radius: 19px;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;
  p {
    line-height: 16px;
    margin-bottom: 10px;
  }
  h1 {
    margin: 0;
  }
  &.contents {
    background-color: $purple;
  }
  &.scripts {
    background-color: $green1;
  }
  &.comments {
    background-color: $orange;
  }
}

.dash-tasks {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 120px;
  padding: 15px 10px;
  text-align: center;
  p {
    margin-bottom: 10px;
  }
  h1 {
    margin: 0;
    color: $grey4;
  }
  &:hover {
    background: $gradient1;
    color: $white;
    cursor: pointer;
    h1 {
      color: $white;
    }
  }
  &.active {
    background: $gradient1;
    color: $white;
    h1 {
      color: $white;
    }
  }
}

.task-list {
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  h5 {
    color: $grey4;
    margin: 0 0 4px 0;
  }
  .disciplines {
    color: $grey4;
    font-size: 16px;
    margin: 0;
  }
  &.date {
    h5 {
      background: -webkit-linear-gradient(left, $orange, $purple);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    &:after {
      content: "";
      background: $gradient1;
      border-radius: 60px;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
    }
  }
}

.owl-dots {
  .owl-dot {
    span {
      background-color: $grey5 !important;
      min-height: 22px;
      min-width: 22px;
      margin: 12px !important;
    }
    &.active {
      span {
        background: $gradient1 !important;
      }
    }
  }
}

.box-scripts {
  background-color: $white;
  border-radius: 19px;
  min-height: 169px;
  padding: 20px 25px 15px;
  text-align: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  svg {
    margin-bottom: 10px;
  }
  h5 {
    color: $grey4;
  }
  span {
    color: $grey3;
    display: block;
    font-size: 16px;
    text-align: left;
  }
  .progress {
    border-radius: 30px;
    height: 7px;
    .progress-bar {
      background-color: $purple;
    }
    &.orange {
      .progress-bar {
        background-color: $orange2;
      }
    }
    &.yellow {
      .progress-bar {
        background-color: $yellow;
      }
    }
    &.red {
      .progress-bar {
        background-color: $red;
      }
    }
    &.green {
      .progress-bar {
        background-color: $green2;
      }
    }
  }
  p {
    color: $grey4;
    font-size: 12px;
    margin: 0;
    text-align: left;
  }
}

.legend {
  color: $grey4;
  margin-bottom: 90px;
  svg {
    margin-right: 20px;
  }
}

.meetings {
  .tags {
    background-color: $blue;
    border-radius: 5px;
    color: $white;
    font-weight: $bold;
    line-height: 28px;
    max-width: 82px;
    text-align: center;
    width: 100%;
  }
}
</style>
