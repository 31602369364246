<template>
  <header>
    <b-container fluid>
      <b-row align-v="center" class="content-header">
        <b-col md="2" lg="2">
          <router-link
            v-if="
              !hasPermission([
                'admin',
                'parent',
                'teacher',
                'secretary',
                'concierge',
              ]) &&
              !this.currentUser.role.includes('qrcode') &&
              !this.currentUser.role.includes('attendance_manager')
            "
            :to="{
              name: 'dashboard-aluno',
            }"
          >
            <Logo />
          </router-link>
          <router-link
            v-else-if="this.currentUser.role.includes('qrcode')"
            :to="{
              name: 'qrcode',
            }"
          >
            <Logo />
          </router-link>
          <router-link
            v-else-if="!hasPermission(['admin']) && hasPermission(['parent'])"
            :to="{
              name: `${linkTeacherRole}`,
            }"
          >
            <Logo />
          </router-link>
          <router-link
            v-else-if="
              hasPermission(['teacher']) && !hasPermission(['coordinator'])
            "
            :to="{
              name: 'indicadores',
            }"
          >
            <Logo />
          </router-link>
          <router-link
            v-else-if="
              hasPermission(['secretary']) && !hasPermission(['coordinator'])
            "
            :to="{
              name: 'indicadores',
            }"
          >
            <Logo />
          </router-link>
          <router-link
            v-else-if="this.currentUser.role.includes('attendance_manager')"
            to="registro/faltas-e-presencas"
          >
            <Logo />
          </router-link>
          <router-link
            v-else
            :to="{
              name: 'indicadores',
            }"
          >
            <Logo />
          </router-link>
        </b-col>
        <b-col cols="2" sm="7" md="8" lg="8">
          <b-navbar class="menu align-center show-large-only">
            <b-navbar-nav>
              <router-link
                v-if="
                  !hasPermission(['admin']) ? hasPermission(['student']) : ''
                "
                :to="{ name: 'dashboard-aluno' }"
                >Início</router-link
              >
              <router-link
                v-if="
                  !hasPermission(['admin']) &&
                  hasPermission(['parent']) &&
                  hideToTeacher
                "
                :to="{ name: 'dashboard' }"
                >Início</router-link
              >

              <router-link
                v-if="hasPermission(['teacher', 'secretary']) && hideToParent"
                :to="{ name: 'indicadores' }"
                >Indicadores</router-link
              >

              <router-link
                v-if="
                  (hasPermission(['teacher']) && hideToParent) ||
                  hasPermission(['secretary'])
                "
                :to="{
                  name: hasPermission(['teacher', 'student'])
                    ? 'comunidade'
                    : 'professores',
                }"
                active-class="router-link-exact-active"
              >
                Comunidade
                <ul
                  class="submenu"
                  v-if="hasPermission(['teacher', 'secretary'])"
                >
                  <li>
                    <router-link
                      :to="{ name: 'professores' }"
                      v-if="hasPermission(['secretary'])"
                      >Professores</router-link
                    >
                    <router-link :to="{ name: 'alunos' }">Alunos</router-link>
                    <router-link :to="{ name: 'tutoria' }">Tutoria</router-link>
                    <router-link
                      :to="{ name: 'ciclos-turmas-tutores' }"
                      v-if="hasPermission(['coordinator'])"
                      >Ciclos, Turmas e Tutores</router-link
                    >
                  </li>
                </ul>
              </router-link>

              <router-link
                v-if="
                  !hasPermission(['admin']) &&
                  hasPermission(['parent']) &&
                  hideToTeacher
                "
                :to="{ name: 'matriculas' }"
                >Matrícula</router-link
              >

              <router-link
                v-if="
                  this.currentUser.role.includes('attendance_manager') &&
                  hideToTeacher
                "
                :to="{ name: 'registro' }"
                >Faltas e Presenças
              </router-link>

              <router-link
                :to="{ name: 'secretaria' }"
                active-class="router-link-exact-active"
                v-if="hasPermission(['secretary'])"
              >
                Secretaria
                <ul class="submenu">
                  <li>
                    <router-link :to="{ name: 'pedidos' }">Pedidos</router-link>
                    <router-link :to="{ name: 'ano-letivo' }"
                      >Ano letivo</router-link
                    >
                    <router-link :to="{ name: 'listar-contratos' }"
                      >Contratos</router-link
                    >
                    <router-link
                      :to="{ name: 'listar-contratos-inadimplentes' }"
                      >Contratos inadimplentes</router-link
                    >
                    <router-link :to="{ name: 'faltas-presencas' }"
                      >Faltas e presenças</router-link
                    >
                    <router-link :to="{ name: 'turmas' }"
                      >Alterações Internas</router-link
                    >
                    <router-link
                      :to="{ name: 'solicitacoes-de-exclusao-de-dados' }"
                      >Solicitações de exclusão</router-link
                    >
                    <router-link :to="{ name: 'links' }"
                      >Link de Matrícula</router-link
                    >
                  </li>
                </ul>
              </router-link>

              <router-link
                v-if="hasPermission()"
                :to="{ name: 'coordenacao' }"
                active-class="router-link-exact-active"
              >
                Coordenação
                <ul class="submenu">
                  <li>
                    <router-link :to="{ name: 'permissoes' }"
                      >Autorizações</router-link
                    >
                    <router-link :to="{ name: 'listar-conteudos' }"
                      >Currículo</router-link
                    >
                  </li>
                </ul>
              </router-link>

              <router-link
                v-if="
                  (hasPermission(['teacher']) && hideToParent) ||
                  hasPermission(['student'])
                "
                :to="{
                  name: hasPermission(['teacher'])
                    ? 'aprendizagem'
                    : 'registrar-aprendizagem',
                  params: { id: currentUser.student_id },
                }"
                active-class="router-link-exact-active"
              >
                Aprendizagem
                <ul class="submenu">
                  <li v-if="hasPermission(['teacher'])">
                    <router-link :to="{ name: 'roteiros' }" v-if="false"
                      >Roteiros</router-link
                    >
                    <router-link :to="{ name: 'conteudos' }"
                      >Registro de Aprendizagem</router-link
                    >
                  </li>
                  <li
                    v-if="
                      !hasPermission(['admin']) && hasPermission(['student'])
                    "
                  >
                    <router-link
                      :to="{
                        name: 'registrar-aprendizagem',
                        params: { id: currentUser.student_id },
                      }"
                      >Conteudos</router-link
                    >
                    <router-link :to="{ name: 'roteiros-aluno' }" v-if="false"
                      >Roteiros</router-link
                    >
                  </li>
                  <li v-if="hasPermission(['teacher'])">
                    <router-link :to="{ name: 'roteiros' }" v-if="false"
                      >Roteiros</router-link
                    >
                  </li>
                  <li v-if="hasPermission(['teacher', 'secretary'])">
                    <router-link :to="{ name: 'painel-de-avaliacoes' }"
                      >Painel de Avaliações</router-link
                    >
                  </li>
                </ul>
              </router-link>

              <router-link
                :to="{ name: 'portaria' }"
                active-class="router-link-exact-active"
                v-if="hasPermission(['concierge'])"
              >
                Portaria
              </router-link>

              <router-link
                v-if="canShowAppModules()"
                :to="{
                  name: isAdmin() ? 'usuarios' : 'eventos',
                }"
                active-class="router-link-exact-active"
              >
                Administração
                <ul class="submenu">
                  <li v-if="isAdmin()">
                    <router-link :to="{ name: 'usuarios' }"
                      >Usuários</router-link
                    >
                  </li>
                  <li v-if="rdaUnit == 'BELO_HORIZONTE'">
                    <router-link :to="{ name: 'eventos' }">Eventos</router-link>
                  </li>
                  <li v-if="rdaUnit == 'BELO_HORIZONTE'">
                    <router-link :to="{ name: 'banners' }">Banners</router-link>
                  </li>
                  <li v-if="rdaUnit == 'BELO_HORIZONTE'">
                    <router-link :to="{ name: 'notifications' }"
                      >Avisos</router-link
                    >
                  </li>
                  <li v-if="rdaUnit == 'BELO_HORIZONTE'">
                    <router-link
                      :to="{ name: 'contractors-attendances-notifications' }"
                      >Notificações de Faltas</router-link
                    >
                  </li>
                  <li v-if="isAdmin() && rdaUnit == 'BELO_HORIZONTE'">
                    <router-link :to="{ name: 'blog' }">Blog</router-link>
                  </li>
                  <li v-if="isAdmin() && rdaUnit == 'BELO_HORIZONTE'">
                    <router-link :to="{ name: 'videos' }">Vídeos</router-link>
                  </li>
                </ul>
              </router-link>
            </b-navbar-nav>
          </b-navbar>
        </b-col>

        <b-col class="group-user-infos" md="2" lg="2">
          <div class="profile align-right">
            <b-popover
              target="popover-target-1"
              triggers="focus"
              placement="bottom"
            >
              <template #title>Popover Title</template>
              I am popover <b>component</b> content!
            </b-popover>
          </div>
          <b-navbar class="profile align-right" v-if="currentUser.name">
            <b-navbar-nav>
              <b-nav-item-dropdown
                v-if="
                  hasPermission(['secretary', 'teacher']) &&
                  notifications.length !== 0
                "
                id="my-nav-dropdown"
                text="Dropdown"
                toggle-class="nav-link-custom"
                right
              >
                <template slot="button-content">
                  <div class="button-content">
                    <div class="bell-circle" v-if="Unreadnotifications">
                      {{ Unreadnotifications }}
                    </div>
                    <font-awesome-icon icon="fa-regular fa-bell" size="lg" />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="(notification, index) in notifications.slice(0, 5)"
                  :key="index"
                  :to="{ name: 'notificacoes' }"
                >
                  <div>
                    <p id="notificationDate">
                      {{ notification.created_at }}
                    </p>

                    <strong id="notificatoin-body-text">{{
                      notification.EditorUser.name
                    }}</strong>

                    editou dados

                    <strong
                      id="notificatoin-body-text"
                      v-if="
                        notification.user_editor_id ===
                        notification.user_edited_id
                      "
                    >
                      <span class="not-bold"> do </span>próprio usuário
                    </strong>

                    <div v-else>
                      {{ notification.type }}
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'notificacoes' }">
                  <p style="color: #b23066">Ver todas</p>
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item-dropdown
                v-if="
                  hasPermission(['secretary', 'teacher']) &&
                  evaluationNotifications.length !== 0 &&
                  switchViews != 'parent'
                "
                id="my-nav-dropdown"
                text="Dropdown"
                toggle-class="nav-link-custom"
                right
              >
                <template slot="button-content">
                  <div class="button-content">
                    <div class="bell-circle"></div>
                    <font-awesome-icon
                      icon="fa-regular fa-note-sticky"
                      size="lg"
                    />
                  </div>
                </template>
                <b-dropdown-item
                  v-for="(notification, index) in evaluationNotifications.slice(
                    0,
                    5
                  )"
                  :key="index"
                  :to="{
                    name: 'registrar-aprendizagens',
                    params: {
                      id: notification.student_id,
                      subject_id: notification.subject_id,
                    },
                  }"
                >
                  <div>
                    <p id="notificationDate">
                      {{ notification.updated_at }}
                    </p>

                    <strong id="notificatoin-body-text">{{
                      notification.student_name
                    }}</strong>

                    solicitou uma avaliação em

                    <strong id="notificatoin-body-text">
                      <span class="bold">
                        {{ notification.subject_designation }}
                      </span>
                    </strong>
                  </div>
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item :to="{ name: 'painel-de-avaliacoes' }">
                  <p style="color: #b23066">Ver todas</p>
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <b-navbar-brand>
                <img v-if="photo" width="32" height="32" :src="photo" alt />
                <div v-else class="profile_name">
                  <span>{{ getFirstLetterSpan(currentUser.name) }}</span>
                </div>
              </b-navbar-brand>
              <b-nav-item-dropdown right class="name-user">
                <b-form-group
                  v-if="
                    !hasPermission(['admin']) &&
                    hasPermission(['teacher']) &&
                    hasPermission(['parent'])
                  "
                  class="group-radio switch-roles"
                >
                  <div class="radio">
                    <b-form-radio
                      @change="switchRoles('changeToParent')"
                      v-model="switchViews"
                      name="options-view"
                      value="parent"
                      >Mãe/Pai</b-form-radio
                    >
                    <b-form-radio
                      @change="switchRoles('changeToTeacher')"
                      v-model="switchViews"
                      name="options-view"
                      value="teacher"
                      >Professor</b-form-radio
                    >
                  </div>
                </b-form-group>
                <b-dropdown-item
                  v-if="!this.currentUser.role.includes('qrcode')"
                  :to="{ name: 'perfil' }"
                  >Perfil</b-dropdown-item
                >
                <b-dropdown-item href @click.prevent="logOut"
                  >Sair</b-dropdown-item
                >
              </b-nav-item-dropdown>
            </b-navbar-nav>
          </b-navbar>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

import Logo from "@/components/Logo.vue";
import httpRda from "../http-rda";
import moment from "moment-timezone";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faBell, faNoteSticky } from "@fortawesome/free-regular-svg-icons";

library.add(faBell);
library.add(faNoteSticky);

export default {
  components: {
    Logo,
    FontAwesomeIcon,
  },

  data() {
    return {
      currentYear: "",
      newPhoto: null,
      rdaUnit: process.env.VUE_APP_RDA_UNIT,
      textToggle: "Mãe/Pai",
      linkTeacherRole: "indicadores",
      switchViews: "teacher",

      hideToTeacher: true,
      hideToParent: true,

      notifications: [],
      evaluationNotifications: [],
      Unreadnotifications: 0,
      unreadEvaluationNotifications: 0,
    };
  },

  mounted() {
    this.currentYear = new Date().getUTCFullYear().toString();

    if (
      this.currentUser.role.includes("teacher") &&
      this.currentUser.role.includes("parent")
    ) {
      this.hideToTeacher = false;
    }
    if (
      (this.currentUser.role.includes("teacher") &&
        this.currentPage("dashboard")) ||
      this.currentPage("matriculas") ||
      this.currentPage("faltas-e-presencas") ||
      this.currentPage("registrar-aprendizagem") ||
      this.currentPage("roteiros-aluno")
    ) {
      this.hideToTeacher = true;
      this.hideToParent = false;
      this.linkTeacherRole = "dashboard";
      this.switchViews = "parent";
    }
    if (this.hasPermission(["secretary", "teacher"])) {
      this.getNotifications();
    }
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
      users: "shared/users",
      user: "auth/getAuthData",
    }),

    photo() {
      return this.setPhoto();
    },
  },

  watch: {
    photo() {
      return this.setPhoto();
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$store.commit("shared/RESET_STATE");
      document.body.classList.add("disabled");
      window.location.reload();
    },

    addEllipsisIfExceedsLimit(name) {
      if (name.length > 10) {
        return name.substring(0, 10) + "...";
      } else {
        return name;
      }
    },

    getFirstLetterSpan(name) {
      if (name && name.length > 0) {
        const firstLetter = name.charAt(0).toUpperCase();
        return firstLetter;
      } else {
        return "";
      }
    },

    canShowAppModules() {
      return this.rdaUnit == "BELO_HORIZONTE"
        ? this.hasPermission("secretary")
        : this.isAdmin();
    },

    isAdmin() {
      return this.currentUser.role.some((r) => r === "admin");
    },

    hasPermission(roles = []) {
      const rolesComplete = [...roles, "admin", "coordinator"];
      if (!this.currentUser.role) {
        return false;
      }

      return this.currentUser.role.some((r) => rolesComplete.includes(r));
    },

    formatNotificationDate(date) {
      const now = moment.tz("America/Sao_Paulo");
      const created_at = moment.tz(date, "America/Sao_Paulo").startOf("day");
      const daysAgo = now.startOf("day").diff(created_at, "days");
      let result;

      if (daysAgo === 1) {
        result =
          "Ontem às " + moment.tz(date, "America/Sao_Paulo").format("HH:mm");
      } else if (daysAgo === 0) {
        result =
          "Hoje às " + moment.tz(date, "America/Sao_Paulo").format("HH:mm");
      } else {
        result =
          daysAgo +
          " dias atrás às " +
          moment.tz(date, "America/Sao_Paulo").format("HH:mm");
      }

      return result;
    },

    async getNotifications() {
      await httpRda.get("/notifications").then((response) => {
        this.notifications = response.data.notifications;
        this.evaluationNotifications = response.data.evaluationsNotifications;
      });

      this.notifications.map((notification) => {
        notification.created_at = this.formatNotificationDate(
          notification.created_at
        );

        if (!notification.readed_at) {
          this.Unreadnotifications++;
        }

        return notification;
      });

      this.evaluationNotifications.map((notification) => {
        notification.updated_at = this.formatNotificationDate(
          notification.updated_at
        );

        return notification;
      });
    },

    switchRoles(selectedView) {
      this.onLoadAnimation();
      if (selectedView === "changeToParent") {
        this.switchViews = "parent";
        this.hideToTeacher = true;
        this.hideToParent = false;
        this.linkTeacherRole = "dashboard";
        this.$router.push({ name: "dashboard" });
      } else if (selectedView === "changeToTeacher") {
        this.switchViews = "teacher";
        this.hideToTeacher = false;
        this.hideToParent = true;
        this.linkTeacherRole = "indicadores";
        this.$router.push({ name: "indicadores" });
      }
    },

    currentPage(url) {
      return window.location.href.includes(url);
    },

    onLoadAnimation() {
      document.querySelector("#loading").classList.add("animate");
      setTimeout(function () {
        document.querySelector("#loading").classList.remove("animate");
      }, 1500);
    },

    setPhoto() {
      if (this.newPhoto) return this.newPhoto;
      else if (this.users.photo) return this.users.photo;
      else if (!this.users.photo) return this.currentUser.photo;
      else return require("@/assets/images/default-photo.png");
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/variables.scss";

header {
  background-color: $white;
  position: relative;
  z-index: 200;
  border-bottom: 2px solid $grey7;

  .content-header {
    max-width: 1390px;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .profile_name {
    background-color: #adadad;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: #fff;
      font-size: 14px !important;
      font-weight: bold;
      position: relative;
      top: 1px;
    }
  }

  .bell-circle {
    width: 14px;
    height: 14px;
    border-radius: 10px;
    background-color: rgb(233, 69, 19);
    align-items: center;
    position: absolute;
    text-align: center;
    display: flex;
    justify-content: center;
    top: -3px;
    right: -5px;
    color: white;
    font-size: 9px;
  }

  .not-bold {
    font-weight: normal;
  }

  .row {
    min-height: 80px;
    .menu {
      padding: 0;
      line-height: 80px;
      .navbar-nav {
        font-size: 18px;
        justify-content: center;
        a {
          min-width: 32px;
          margin: 0 8px;
          padding: 0 16px;
          position: relative;
          text-align: center;
          transition: all 0.15s;

          @media screen and (max-width: 1080px) {
            font-size: 16px;
            padding: 0 6px;
          }

          &:after {
            content: "";
            width: 0px;
            transition: all 0.15s;
          }
          &.router-link-exact-active {
            font-weight: $bold;
            color: $purple;

            &:after {
              background: $gradient1;
              border-radius: 30px;
              bottom: 0;
              content: "";
              display: block;
              left: 0;
              position: absolute;
              height: 4px;
              width: 100%;
            }
          }
          &:hover {
            &:after {
              background: $gradient1;
              border-radius: 30px;
              bottom: 0;
              content: "";
              display: block;
              left: 0;
              position: absolute;
              height: 4px;
              width: 100%;
            }
          }
          .submenu {
            background-color: $white;
            border-top: 2px solid $grey7;
            box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
            display: none;
            line-height: 30px;
            border: 1px solid $white;
            border-top-color: rgba(0, 0, 0, 0.1);
            padding: 0;
            position: absolute;
            top: 80px;
            left: 0;
            z-index: 999;
            border-radius: 0 0 6px 6px;

            li {
              list-style: none;
              width: 220px;
              a {
                display: block;
                font-weight: $regular;
                font-size: 16px;
                line-height: 35px;
                margin: 0;
                text-align: left;
                &.router-link-exact-active {
                  // font-weight: $bold;
                  background: $gradient1;
                  color: $white;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
          &:hover {
            .submenu {
              display: block;
              a {
                &:hover {
                  background-color: $grey6;
                }
                &:after {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  #my-nav-dropdown {
    .dropdown-toggle::after {
      display: none;
    }
  }
  #my-nav-dropdown__BV_toggle_ {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0;
  }
  #my-nav-dropdown__BV_toggle_menu_ {
    border-radius: 10px;
  }

  #notificationDate {
    font-size: 14px;
    margin-bottom: 1px;
    color: #666666;
  }

  #notificatoin-body-text {
    font-size: 18px;
  }

  .profile {
    img {
      border-radius: 50%;
    }
    .dropdown-menu {
      .dropdown-item {
        p {
          font-size: 14px;
          margin: 0;
          line-height: 20px;
        }
        &:active {
          color: $grey9;
          text-decoration: none;
          background-color: $grey8;
        }
      }
    }
  }
  .navbar-brand {
    margin-right: 4px;
    span {
      font-size: 18px;
    }
  }
  .name-user {
    display: flex;
    align-items: center;

    > a {
      padding: 0;
    }
    .dropdown-toggle::after {
      vertical-align: 0.155rem;
    }
  }
}

.switch-roles {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 0.5rem !important;
  padding: 0.25rem 1.5rem 0.6rem;
  .custom-radio {
    padding: 0.5rem 0;
    input {
      width: 100%;
      z-index: 99;
      cursor: pointer;
      height: 100%;
      top: 0;
    }
  }
}
.group-user-infos {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-content {
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  position: relative;

  .fa-lg {
    font-size: 1.45em;
  }
}
</style>
