var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"container-rda"},[(this.hasStudents.Students.length > 0)?_c('b-row',{staticClass:"pt-5 mt-4 mb-5"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Bem-vindo(a)!")])]),_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Selecione o perfil desejado:")])])],1):_vm._e(),(this.responsiblesStudent.Students.length == 0)?_c('b-row',{staticClass:"pt-5 mt-4 mb-5"},[_c('b-col',[_c('h2',{staticClass:"purple bold"},[_vm._v("Não há Alunos matriculados.")]),_c('p',[_vm._v(" Por favor, aguarde. Vocês será redirecionado para realizar a Matricula. ")])])],1):_vm._e(),_vm._l((_vm.responsibles),function(responsible){return _c('b-row',{key:responsible.id},_vm._l((responsible.Students),function(student){return _c('b-col',{key:student.id,attrs:{"md":"3"}},[_c('div',{staticClass:"dash-profile",class:{ active: _vm.selectedIndex == student.id }},[_c('router-link',{attrs:{"to":{
            name: 'aluno-perfil',
            params: { id: student.User.id },
          }}},[_c('EditIcon2')],1),(student.User.photo == null || '')?_c('img',{attrs:{"src":require("@/assets/images/default-photo.png"),"height":"64","width":"64","alt":""}}):_c('img',{attrs:{"width":"64","height":"64","src":("" + (student.User.photo)),"alt":""}}),_c('h5',[_vm._v(_vm._s(student.User.name))]),(student && student.Classroom && student.Classroom.year_id)?_c('p',[_vm._v(" "+_vm._s(student.Classroom.year_id)+"º ano do Ciclo: "+_vm._s(_vm.getCycleName(student.cycle_id))+" ")]):_c('p',[_vm._v(" "+_vm._s(_vm.getCycleName(student.cycle_id))+" ")]),_c('b-form-group',{staticClass:"group-radio"},[_c('div',[_c('b-form-radio',{attrs:{"name":"profile","value":"profile-1"},on:{"change":function($event){return _vm.setSelected(student, student.id)}}},[_vm._v("Selecionar perfil")])],1)])],1)])}),1)}),(_vm.selected)?_c('b-row',{staticClass:"mt-5 pt-5"},[_c('b-col',[_c('router-link',{staticClass:"dash-box contents",attrs:{"to":{
          name: 'registrar-aprendizagem',
          params: { id: _vm.selected.id },
        }}},[_c('p',[_vm._v("Conteúdos")]),_c('h1',[_vm._v(_vm._s(_vm.topics.count))])])],1),(false)?_c('b-col',[_c('router-link',{staticClass:"dash-box scripts",attrs:{"to":{
          name: 'roteiros-aluno',
          params: { id: _vm.selected.id, student_id: _vm.selected.id },
        }}},[_c('p',[_vm._v("Roteiros")]),_c('h1',[_vm._v(_vm._s(_vm.countSyllabuses))])])],1):_vm._e(),_c('b-col',[_c('router-link',{staticClass:"dash-box blue",attrs:{"to":{
          name: 'faltas-e-presencas',
          params: { student_id: _vm.selected.id },
        }}},[_c('p',[_vm._v("Faltas")]),_c('h1',[_vm._v(_vm._s(_vm.attendances.absences))])])],1),_c('b-col',[_c('router-link',{staticClass:"dash-box comments",attrs:{"to":{
          name: 'faltas-e-presencas',
          params: { student_id: _vm.selected.id },
        }}},[_c('p',[_vm._v("Frequência")]),_c('h1',[_vm._v(_vm._s(_vm.attendances.frequency)+"%")])])],1),(
        _vm.selected.Classroom.cycle_id != 8 && _vm.selected.Classroom.cycle_id != 6
      )?_c('b-col',[_c('router-link',{staticClass:"dash-box tutoring",attrs:{"to":{
          name: 'dashboard-tutoria-aluno',
          params: { id: _vm.selectedIndex },
        }}},[_c('p',[_vm._v("Ver")]),_c('h1',[_vm._v("Tutoria")])])],1):_vm._e(),(
        _vm.selected.Classroom.cycle_id == 8 || _vm.selected.Classroom.cycle_id == 6
      )?_c('b-col',[_c('router-link',{staticClass:"dash-box tutoring",attrs:{"to":{
          name: 'dashboard-timeline-aluno-parent',
          params: { id: _vm.selected.id },
        }}},[_c('p',[_vm._v("Ver")]),_c('h1',[_vm._v("Ed. Infantil")])])],1):_vm._e()],1):_vm._e(),(_vm.selected)?_c('b-row',{staticClass:"pt-5 mt-5 mb-4"},[_c('b-col',[_c('h3',[_vm._v("Tarefas da semana")])])],1):_vm._e(),(_vm.selected)?_c('b-row',[_c('b-col',[_c('h5',{staticClass:"mb-3 purple captalize"},[_vm._v(_vm._s(_vm.month))])]),_c('div',{staticClass:"w-100"}),_vm._l((_vm.week),function(day,index){return _c('b-col',{key:day.label},[_c('div',{staticClass:"dash-tasks",class:{ active: index == _vm.selectedDate.getDay() },on:{"click":function () { return _vm.selectDay(day.fullDate); }}},[_c('p',[_vm._v(_vm._s(day.label))]),_c('h1',[_vm._v(_vm._s(day.day))])])])})],2):_vm._e(),_c('b-row',{staticClass:"mt-5"},[_c('b-col',[_c('carousel',{attrs:{"perPageCustom":[
          [360, 3],
          [1366, 3] ]}},_vm._l((_vm.syllabusesThisWeek),function(syllabus){return _c('slide',{key:syllabus.id},[_c('div',{staticClass:"task-list",class:{ date: syllabus.isToday }},[_c('h5',[_vm._v("Entrega "+_vm._s(_vm.formatSyllabusDeadline(syllabus)))]),_c('p',[_vm._v(_vm._s(syllabus.name))]),_c('p',{staticClass:"disciplines"},[_vm._v(_vm._s(syllabus.subject.join(", ")))])])])}),1)],1)],1),(_vm.selected)?_c('b-row',{staticClass:"pt-5 mt-5 mb-4"},[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v("Conteúdos em estudo")])])],1):_vm._e(),(_vm.selected)?_c('b-row',[(!_vm.topicsFormated.length > 0)?_c('b-col',[_c('p',[_vm._v("Não há conteúdos em estudo")])]):_c('b-col',[_c('carousel',{attrs:{"perPageCustom":[
          [360, 3],
          [1366, 6] ],"paginationColor":"$grey5"}},_vm._l((_vm.topicsFormated),function(subject){return _c('slide',{key:subject.id},[_c('div',{staticClass:"box-scripts"},[_c('b-row',[_c('b-col',[_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showModal(subject)}}},[_c('SubjectsIcon',{attrs:{"value":subject.designation}}),_c('h5',[_vm._v(_vm._s(subject.designation))])],1)])],1),_c('b-row',{attrs:{"align-v":"center","no-gutters":""}},[_c('b-col',[(subject.topics && subject.topics.length > 1)?_c('span',[_vm._v("Há "),_c('strong',[_vm._v(_vm._s(subject.topics.length))]),_vm._v(" tópicos com status \"EE\"")]):_vm._e(),(subject.topics && subject.topics.length == 1)?_c('span',[_vm._v("Há "),_c('strong',[_vm._v(_vm._s(subject.topics.length))]),_vm._v(" tópicos com status \"EE\"")]):_vm._e(),(subject.topics && subject.topics.length == 0)?_c('span',[_vm._v("Não há tópicos")]):_vm._e()]),_c('div',{staticClass:"w-100"})],1)],1)])}),1)],1)],1):_vm._e(),(_vm.selected)?_c('div',[_c('SubjectsProgressBars',{attrs:{"percentages":_vm.percentages,"subjects":_vm.subjects,"desiredPercentage":_vm.desiredPercentage}})],1):_vm._e(),(_vm.subjectSelected)?_c('b-modal',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"},attrs:{"id":"modal-script","hide-footer":"","title":_vm.subjectSelected.designation}},_vm._l((_vm.subjectSelected.competences),function(competence,index){return _c('b-row',{key:("competence-" + index),staticClass:"rda-viewlist"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h5',{staticClass:"title"},[_vm._v(_vm._s(competence.description))])]),_vm._l((competence.topics),function(topic,indexS){return _c('b-col',{key:("student-" + indexS),attrs:{"cols":"12"}},[_c('div',{staticClass:"list-teacher item-rda-viewlist"},[_c('div',{staticClass:"cols text-col"},[_c('p',[_vm._v(_vm._s(topic))])]),_c('div',{staticClass:"cols"},[_c('p',{staticClass:"tags warning"},[_vm._v("EE")])])])])})],2)}),1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }