<template>
  <b-container class="container-rda">
    <b-row class="mb-4 padding-top">
      <b-col>
        <h2 class="purple bold">Indicadores</h2>
      </b-col>
      <b-col class="text-right"></b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col md="4" lg="2">
        <SelectSimple
          v-model="selectedPeriod"
          label="Turno"
          :options="allPeriods"
          :callback="selectPeriod"
        />
      </b-col>
      <b-col md="4" lg="2">
        <SelectSimple
          v-model="selectedSituation"
          label="Situação"
          :options="allSituations"
          :callback="selectSituation"
        />
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <h5 class="bold">Alunos</h5>
      </b-col>
    </b-row>
    <div v-if="!isLoading">
      <b-row class="mb-5">
        <b-col
          md="4"
          lg="2"
          v-if="dataFromApi.students && dataFromApi.students.actives"
        >
          <div class="indicators-box purple">
            <h5>Ativos</h5>
            <h4>
              {{ dataFromApi.students.actives }}
            </h4>
          </div>
        </b-col>
        <b-col
          md="4"
          lg="2"
          v-if="
            dataFromApi.students && dataFromApi.students.inactivesInCurrentYear
          "
        >
          <div class="indicators-box orange">
            <h5>Inativados este ano</h5>
            <h4>
              {{ dataFromApi.students.inactivesInCurrentYear }}
            </h4>
          </div>
        </b-col>
        <b-col md="4" lg="2" v-if="dataFromApi.evaluations">
          <div class="indicators-box blue">
            <h5>Avaliações</h5>
            <h4>
              {{ dataFromApi.evaluations }}
            </h4>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col>
          <h5 class="bold">Alunos por ciclo</h5>
        </b-col>
      </b-row>
      <b-row
        class="mb-12"
        v-if="
          chartData.datasets[0].data.length > 0 && chartData.labels.length > 0
        "
      >
        <b-col class="box">
          <ui-chart id="myChart" :data="chartData" class="box-chart"></ui-chart>
        </b-col>
      </b-row>
    </div>
    <div
      v-else
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      "
    >
      <b-spinner variant="success" label="Carregando..."></b-spinner>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UiChart from "../../components/chart/chart.vue";
import httpRda from "../../http-rda";
import SelectSimple from "@/components/inputs/SelectSimple.vue";

export default {
  name: "Dashbaord",
  components: { UiChart, SelectSimple },
  data() {
    return {
      allPeriods: [
        { id: null, label: "Todos" },
        { id: "Matutino", label: "Matutino" },
        { id: "Vespertino", label: "Vespertino" },
      ],
      selectedPeriod: { id: null, label: "Todos" },
      allSituations: [
        { id: null, label: "Todos" },
        { id: "Veteranos", label: "Veteranos" },
        { id: "Novatos", label: "Novatos" },
      ],
      selectedSituation: { id: null, label: "Todos" },
      isLoading: false,
      dataFromApi: {
        students: null,
        evaluations: null,
      },
      breadcrumb: [
        {
          text: "Dashboard",
          href: "/",
        },
      ],
      chartData: {
        labels: [],

        datasets: [
          {
            label: "Por Ciclo",
            backgroundColor: [
              "#b2ecf7",
              "#ffc2c2",
              "#d1e7c0",
              "#d8c7ee",
              "#ffd97d",
              "#ff8b94",
              "#ffd9b6",
              "#b2c9f7",
              "#ffc9ed",
              "#a3e0ce",
              "#ffad76",
              "#e7b3c8",
              "#aedbad",
              "#90a8e6",
              "#e6b890",
              "#c2f7d5",
              "#d1b2ec",
              "#ffd4a2",
              "#a9c0e7",
              "#c7d6f2",
              "#ffb6c7",
              "#d9f0c2",
              "#c7b8e6",
              "#ffdf9d",
            ],
            data: [],
            fill: true,
            borderRadius: 15,
          },
        ],
      },
    };
  },

  filters: {
    //
  },

  computed: {
    ...mapGetters({
      attendances: "shared/studentsAttendances",
    }),
  },

  methods: {
    ...mapActions({
      getStudentAttendances: "shared/getStudentAttendances",
    }),

    async getChartData() {
      this.isLoading = true;
      const params = {
        time_period:
          this.selectedPeriod && this.selectedPeriod.id
            ? this.selectedPeriod.id
            : undefined,
        student_situation:
          this.selectedSituation && this.selectedSituation.id
            ? this.selectedSituation.id
            : undefined,
      };

      this.chartData.labels = [];
      this.chartData.datasets[0].data = [];

      const response = await httpRda.get("/dashboard", { params });
      this.dataFromApi = response.data.result;

      this.dataFromApi.students.byCycles.forEach((cycle) => {
        this.chartData.labels.push(cycle.cycle_name);
        this.chartData.datasets[0].data.push(+cycle.count);
      });

      this.isLoading = false;
    },

    async selectPeriod(data) {
      this.selectedPeriod = data;
      await this.getChartData();
    },

    async selectSituation(data) {
      this.selectedSituation = data;
      await this.getChartData();
    },
  },

  created() {
    this.getChartData();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.padding-top {
  padding-top: 40px;
}

.indicators-box {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 10px;

  &.green {
    background-color: #36b67d;
  }
  &.purple {
    background-color: #b23066;
  }
  &.orange {
    background-color: #ef6549;
  }
  &.blue {
    background-color: #49adef;
  }

  h5,
  h4 {
    color: #fff;
    margin: 0;
  }
  h5 {
    font-weight: normal;
    font-size: 14px;
  }
  h4 {
    font-size: 22px;
  }
}

.box-chart {
  width: 100%;
  height: 320px;
  box-sizing: border-box;
}
</style>
